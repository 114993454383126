<template>
  <el-container>
    <el-header height="35px" style="padding:5px;">
      <button-group :buttons="buttonConfig" @handleClick="callMethod">
      </button-group>
    </el-header>
    <el-main>
      <edit-tree treeTitle="商品分类" :treeData="treeData" @handleNodeClick="handleNodeClick">
      </edit-tree>
    </el-main>
    <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></edit-dialog>
  </el-container>
</template>

<script>
import { submit } from "@/api/base";
export default {
  name: "productCategory",
  data() {
    return {
      buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
      selectRows: [],
      treeData: [],
      dialogVisible: false,
      editFormConfig: {},
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    async handleQuery() {
      const { data } = await submit("/api/product/category/getTree");
      this.treeData = data;
      this.selectRows = [];
    },
    handleNodeClick(data) {
      this.selectRows = [{ CateID: data.value }];
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    handleSearch(_this, button) {
      _this.handleQuery();
    },
    handleAdd(_this, button) {
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.editDialog.init("新增", {
          parentID: _this.selectRows.length > 0 ? _this.selectRows[0].CateID : 0,
        });
      });
    },
    handleEdit(_this, button) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (_this.selectRows[0].CateID == 0) {
        _this.$baseMessage("根节点不允许修改", "error");
        return false;
      }
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.editDialog.init("修改", {
          cateID: _this.selectRows[0].CateID,
        });
      });
    },
    handleDelete(_this, button) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage("请选择要删除的数据", "error");
        return false;
      }
      if (_this.selectRows[0].CateID == 0) {
        _this.$baseMessage("根节点不允许删除", "error");
        return false;
      }
      _this.$baseConfirm("确定选中项要删除吗？", null, async () => {
        await submit(button.url, {
          cateID: _this.selectRows[0].CateID,
        });
        _this.$baseMessage("删除成功", "success");
        _this.handleQuery();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>