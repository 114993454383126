<template>
    <div class="authorize">
        <div :class="isSuccess ? 'success-status' : 'error-status'">
            <i :class="isSuccess ? 'el-icon-check' : 'el-icon-close'"></i>
        </div>
        <div class="content">
            <div :class="isSuccess ? 'success-title' : 'error-title'">
                {{ isSuccess ? "授权成功" : "授权失败" }}
            </div>
            <div class="msg">{{ msg }}</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "authorizeCallback",
        data() {
            return {
                isSuccess: true,
                msg: "",
                countDown: 3,
            };
        },
        beforeCreate() {
            document.querySelector("body").style =
                "background:url('/static/img/background.png') no-repeat;background-size:100% 100%;background-attachment:fixed;margin:0;";
        },
        mounted() {
            this.$nextTick(() => {
                this.handleCallback(this.$route.query);
            });
        },
        beforeDestroy() {
            document.querySelector("body").removeAttribute("style");
        },
        methods: {
            async handleCallback(query) {
                if (query.error) {
                    this.isSuccess = false;
                    this.msg = query.error;
                }
                else if (query.token) {
                    await this.$store.dispatch('user/setAccessToken', query);
                    this.$router.push({ path: "/appHome" });
                }
                else {
                    this.handleCountDown();
                }
            },
            handleCountDown() {
                if (this.countDown <= 0) {
                    window.close();
                } else {
                    this.msg = `${this.countDown--}秒后自动关闭页面`;
                    setTimeout(() => {
                        this.handleCountDown();
                    }, 1000);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @mixin status {
        height: 60px;
        width: 60px;
        border: 6px solid;
        border-radius: 40px;
        color: white;
        text-align: center;
        line-height: 60px;
        position: absolute;
        font-size: 55px;
        font-weight: 900;
        top: 0;
        left: 50%;
        margin-left: calc(-60px / 2);
        margin-top: calc(-60px / 2);
    }

    @mixin title {
        font-size: 25px;
        font-weight: 600;
        margin: 0 0 20px 10px;
    }

    .authorize {
        height: 200px;
        width: 400px;
        background: white;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: calc(-400px / 2);
        margin-top: calc(-350px / 2);
        border-radius: 8px;

        .success-status {
            @include status;
            border-color: #e7f3fe;
            background: #0178e6;
        }

        .error-status {
            @include status;
            border-color: #ffe8e8;
            background: #f96868;
        }

        .content {
            width: 100%;
            height: 165px;
            position: absolute;
            bottom: 0;
            padding: 20px;
            box-sizing: border-box;
            text-align: center;

            .success-title {
                @include title;
                color: #0075e3;
            }

            .error-title {
                @include title;
            }

            .msg {
                margin-left: 10px;
            }
        }
    }
</style>