<template>
  <el-dialog v-dialog-drag :title="title" :visible="visible" :close-on-click-modal="false" :width="editForm.dialogWidth"
    @close="close">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="基础信息" name="info">
        <el-form ref="editForm" :model="editForm.data" :rules="editForm.rules" :inline="editForm.isInline"
          label-position="right" :label-width="editForm.labelWidth" :inline-message="true">
          <template>
            <edit-form-item v-for="(item, index) in editForm.items.slice(0, 11)" :key="index" :form="editForm.data"
              :item="item" :labelWidth="editForm.labelWidth" :tipWidth="editForm.tipWidth"></edit-form-item>
          </template>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="商品图片" name="img">
        <div>
          <el-upload ref="img" :action="uploadUrl" :headers="headers" accept="image/*" :show-file-list="false"
            :on-success="(response) => uploadSuccess(response, 'img')">
            <el-button size="small" type="primary" :style="{ width: '80px' }">上传图片</el-button>
          </el-upload>
        </div>
        <div :style="{
          height: '370px',
          'overflow-y': 'auto',
          border: '1px solid #ccc',
          margin: '10px 0',
          'padding-left': '10px',
        }">
          <el-card v-for="(img, index) in imgList" :key="index" :body-style="{ padding: '0' }" :style="{
            width: '150px',
            display: 'inline-block',
            margin: '10px 10px 0 0',
          }">
            <div :style="{ width: '100%', height: '150px' }">
              <img :src="img.ImgPath" class="image" :style="{ width: '100%', height: '100%' }" />
            </div>
            <div :style="{
              padding: '5px',
              background: '#e6e8ea',
              'text-align': 'right',
              position: 'relative',
            }">
              <i v-if="img.IsMain" class="el-icon-check" :style="{
                color: 'white',
                background: '#62cc62',
                position: 'absolute',
                top: '9px',
                left: '5px',
              }"></i>
              <el-link type="info" :underline="false" @click="deleteImg(index, img.ImgID, img.ImgPath)">[删除]</el-link>
              <el-link type="info" :underline="false" @click="setMainImg(index)">[设为主图]</el-link>
            </div>
          </el-card>
        </div>
        <div>
          <div v-for="img in otherImgList" :key="img.prop" :style="{
            'text-align': 'center',
            display: 'inline-block',
            'margin-right': '20px',
          }">
            <img :src="(editForm.data || {})[img.prop] || noImgUrl" class="image" :style="{
              width: '100px',
              height: '100px',
              'margin-bottom': '6px',
            }" />
            <el-upload :ref="img.prop" :action="uploadUrl" :headers="headers" accept="image/*" :show-file-list="false"
              :on-success="(response) => uploadSuccess(response, img.prop)">
              <el-button size="small" type="primary" :style="{ width: '100px' }">{{ img.label }}</el-button>
            </el-upload>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="商品规格" name="spec">
        <div>
          <el-button type="primary" @click="showProp">选择规格</el-button>
          <el-button type="primary" @click="autoCode">自动编码</el-button>
        </div>
        <div :style="{ 'margin-top': '10px' }">
          <grid-table ref="gridTable" :tableConfig="tableConfig" :showCheckboxColumn="false" :showPagination="false"
            @rowOperation="rowOperation">
          </grid-table>
        </div>
        <prop-selector ref="propSelector" v-if="propSelectorDialogVisible" @setSpec="setSpec"></prop-selector>
      </el-tab-pane>
      <el-tab-pane label="商品描述" name="description">
        <ueditor-wrap v-if="editForm.data" v-model="editForm.data.Description" :config="{
          initialFrameWidth: 915,
          initialFrameHeight: 580,
        }"></ueditor-wrap>
      </el-tab-pane>
      <el-tab-pane label="商品扩展" name="extend">
        <el-form ref="extendForm" :model="editForm.data" :rules="editForm.rules" :inline="editForm.isInline"
          label-position="right" :label-width="editForm.labelWidth" :inline-message="true">
          <edit-form-item v-for="(item, index) in editForm.items.slice(12)" :key="index" :form="editForm.data"
            :item="item" :labelWidth="editForm.labelWidth" :tipWidth="editForm.tipWidth"></edit-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { baseURL, successCode, noImgUrl, tokenName } from "@/config";
import { submit } from "@/api/base";

import PropSelector from "@/views/product/prop/components/PropSelector.vue";
export default {
  name: "ProductEdit",
  components: {
    PropSelector,
  },
  props: {
    formConfig: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      visible: false,
      title: "",
      weightUnit: "",
      activeName: "info",
      editForm: {},
      uploadUrl:
        baseURL + "/api/base/file/uploadImg?path=product",
      headers: {
        Authorization: "Bearer " + this.$store.getters["user/accessToken"],
      },
      imgList: [],
      otherImgList: [
        { prop: "CredPackage", label: "包装图[上传]" },
        { prop: "CredTag", label: "吊牌图[上传]" },
        { prop: "CredQuality", label: "合格证[上传]" },
        { prop: "CredInspection", label: "质检报告[上传]" },
      ],
      noImgUrl,
      tableConfig: {
        data: [],
        columns: [],
      },
      propSelectorDialogVisible: false,
    };
  },
  methods: {
    init(title, productID, cateID) {
      this.visible = true;
      this.title = title;
      this.$nextTick(() => {
        this.editForm = { ...this.formConfig };
        this.fetchData(productID, cateID);
      });
    },
    async fetchData(productID, cateID) {
      const { data } = await submit("/api/product/getInfo", {
        productID: productID,
        cateID: cateID,
      });
      if (this.title != "新增") {
        this.editForm.data = { ...this.editForm.data, ...data };
        try {
          this.imgList = JSON.parse(this.editForm.data.PhotoList);
        } catch {
          this.imgList = [];
        }
        this.imgList.forEach((img) => {
          if (img.ImgPath == this.editForm.data.DefaultImage) {
            img.IsMain = true;
          }
        });
        let specData = await submit(
          `/api/product/getProductSpec?productID=${productID}&type=edit`
        );
        const { columns, datas } = specData.data;
        this.setSpec(columns, datas);
      }
      let initData = data.InitData || {};
      this.weightUnit = initData.WeightUnit;
      const { items, rules } = this.getFormRules(this.editForm.items, initData);
      this.$set(this.editForm, "items", items);
      this.$set(this.editForm, "rules", rules);
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    uploadSuccess(response, prop) {
      if (successCode.includes(response.code)) {
        if (prop == "img") {
          this.imgList.push({
            ImgID: 0,
            ImgPath: response.data,
            IsMain: this.imgList.length == 0,
          });
        } else {
          this.editForm.data[prop] = response.data;
        }
      } else {
        this.$baseMessage("上传失败，" + response.msg, "error");
      }
      this.$refs.img.clearFiles();
    },
    deleteImg(index, imgID, url) {
      if (imgID == 0) {
        submit("/api/base/file/delete", { url: url });
      }
      this.imgList.splice(index, 1);
    },
    setMainImg(index) {
      this.imgList.forEach((img, imgIndex) => {
        this.$set(this.imgList[imgIndex], "IsMain", index == imgIndex);
      });
    },
    showProp() {
      let vIDs = [];
      this.$refs.gridTable.tableData.forEach((row) => {
        vIDs = vIDs.concat(row.SpecAlter.split("$"));
      });
      vIDs = [...new Set(vIDs)];
      this.propSelectorDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.propSelector.init(vIDs, {
          weightUnit: this.weightUnit,
          price: this.editForm.data.Price,
          userPrice: this.editForm.data.UserPrice,
          cost: this.editForm.data.Cost,
          minimumPrice: this.editForm.data.MinimumPrice,
          weight: this.editForm.data.Weight,
        });
      });
    },
    setSpec(columns, rows) {
      let datas = [];
      rows.forEach((row) => {
        var spec = this.$refs.gridTable.tableData.find(
          (data) => data.Spec == row.Spec
        );
        if (spec) {
          row = { ...row, ...spec };
        }
        datas.push(row);
      });
      if (JSON.stringify(this.tableConfig.columns) != JSON.stringify(columns)) {
        this.tableConfig.columns = columns;
      }
      if (
        JSON.stringify(datas) != JSON.stringify(this.$refs.gridTable.tableData)
      ) {
        this.$refs.gridTable.tableData = datas;
      }
    },
    rowOperation(row, index, method) {
      let methods = this.$options.methods;
      const _this = this;
      methods[method](_this, row, index);
    },
    deleteSpec(_this, row, index) {
      _this.$refs.gridTable.tableData.splice(index, 1);
    },
    async autoCode() {
      if (this.$refs.gridTable.tableData.length == 0) {
        return;
      }
      if (!this.editForm.data.ItemNo) {
        this.$baseMessage("【基础信息】商品货号为空，请输入后重试", "error");
        return;
      }
      const { data } = await submit("/api/product/autoCreateCode", {
        itemNo: this.editForm.data.ItemNo,
        specJson: JSON.stringify(this.$refs.gridTable.tableData),
      });
      this.$refs.gridTable.tableData = data;
    },
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.editForm.data.DefaultImage =
            (this.imgList.find((img) => img.IsMain) || {}).ImgPath || "";
          this.editForm.data.PhotoList = JSON.stringify(this.imgList);
          this.editForm.data.ProductSpec = JSON.stringify(
            this.$refs.gridTable.tableData
          );
          await submit(this.editForm.saveUrl, { ...this.editForm.data });
          if (this.title != "新增") {
            this.$baseMessageBox(
              "保存成功",
              "",
              "success",
              () => {
                this.$emit("handleQuery");
                this.close();
              }
            );
          } else {
            this.$baseMessageBox(
              "保存成功",
              "",
              "success",
              this.continueAdd(),
              "继续新增",
              true,
              () => {
                this.$emit("handleQuery");
                this.close();
              }
            );
          }
        } else if (this.activeName != "info") {
          this.$baseMessage(
            "【基础信息】有输入不正确，请切换至该标签页查看",
            "error"
          );
          return false;
        }
      });
    },
    continueAdd() {
      this.clearData();
    },
    clearData() {
      this.activeName = "info";
      this.$refs.editForm.resetFields();
      this.$refs.extendForm.resetFields();
      this.editForm.data.Description = "";
      this.imgList = [];
      this.tableConfig.columns = [];
      this.$refs.gridTable.tableData = [];
    },
    close() {
      this.clearData();
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
