<template>
    <el-container>
        <el-aside width="25%">
            <el-container>
                <el-header height="36px">
                    <el-autocomplete v-model="PurchaserNameQQ" value-key="PurchaserName" :fetch-suggestions="querySearch"
                        placeholder="搜索采购商" @select="handleSeletedPurchaser($event)" style="width:100%;"></el-autocomplete>
                </el-header>
                <el-main>
                    <el-input id="analyse-content" ref="analyseContent" type="textarea" resize="none" v-model="analyseContent"
                        :placeholder="'请输入报单的内容，示例：\n张三\nHH1234 1\nHH1234 黑色*1 白色*2\nHH1234 黑色 39*1 40*2\n'"></el-input>
                </el-main>
            </el-container>
        </el-aside>
        <el-aside width="70px">
            <div>
                <el-button type="primary" @click="analyse" :disabled="loading" style="margin-top: 10px;">解析</el-button>
            </div>
            <div>
                <el-button type="primary" @click="save" :disabled="loading" style="margin-top:50px;">提交</el-button>
            </div>
            <div>
                <el-button type="danger" @click="clear" style="margin-top: 50px;">清除</el-button>
            </div>
            <div>
                <el-button type="primary" @click="handleImport" style="margin-top: 50px;">导入</el-button>
            </div>
            <div style="position: absolute; bottom: 10px;">
                <el-button type="primary" @click="config" style="margin-top: 10px;">配置</el-button>
            </div>
        </el-aside>
        <el-main style="border-left: 0;">
            <el-container style="border:0">
                <el-main>
                    <stall-trade-table ref="gridTable" :add-enable.sync="addEnable" @handleSave="clear" />
                </el-main>
                <el-footer height="40px">
                    <el-switch v-if="$store.state.routes.systemSetting.WmsEnabled" style="float: right; margin: 10px;"
                        v-model="$store.state.routes.systemSetting.CheckStock" active-text="看缺货"
                        @change="$store.dispatch('routes/changeSettingItem', { CheckStock: $store.state.routes.systemSetting.CheckStock })" />
                </el-footer>
            </el-container>
        </el-main>
        <stall-config ref="analyseConfig" :field.sync="configField" :value.sync="analyseConfig" :level="0" source="analyse"
            :dialog="true">
        </stall-config>
        <trade-import ref="TradeImport" />
    </el-container>
</template>
<script>
import { submit } from "@/api/base";
import StallTradeTable from "@/views/stall/trade/components/StallTradeTable.vue";
import StallConfig from "@/views/stall/trade/components/StallConfig.vue";
import TradeImport from "@/views/stall/trade/components/Import.vue";

export default {
    name: "newStallTradeAdd",
    components: {
        StallTradeTable,
        StallConfig,
        TradeImport
    },
    beforeDestroy() {
        if (this.analyseContent != '') {
            this.$cookies.set('stall_copy_content', this.analyseContent);
        }
        else {
            this.$cookies.remove('stall_copy_content');
        }
    },
    data() {
        return {
            loading: false,
            analyseContent: "",
            configField: {},
            analyseConfig: {},
            addEnable: false,
            checkStock: false,
            PurchaserNameQQ: '',
            searchPurchases: [],
        };
    },
    mounted() {
        var that = this;
        that.$nextTick(() => {
            that.clear();
            let content = that.$cookies.get('stall_copy_content');
            if (content) {
                that.analyseContent = content;//赋值之前内容
            }
            that.loadPurchases();
        });
    },
    methods: {
        //客户名称模糊搜索
        async querySearch(queryString, callback) {
            // 调用 callback 返回建议列表的数据
            var results = queryString ? this.searchPurchases.filter(x => {
                if (x.Name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0) {
                    return true;
                }
                let config = x.Config;
                if (typeof (config) != 'undefined') {
                    if (typeof (config.PinYin) && config.PinYin != '' && config.PinYin.toLowerCase().indexOf(queryString.toLowerCase()) >= 0) {
                        return true;
                    }
                    if (typeof (config.PinYinFirst) && config.PinYinFirst != '' && config.PinYinFirst.toLowerCase().indexOf(queryString.toLowerCase()) >= 0) {
                        return true;
                    }
                }
                return false;
            }) : this.searchPurchases;
            callback(results);
        },
        async handleSeletedPurchaser(selectedRow) {
            let name = selectedRow.Name;
            let content = this.analyseContent;
            if (content == '') {
                this.analyseContent = name + '\n';
            } else {
                if (!(content.trim().endsWith('\n' + name) || content.trim().endsWith(name))){
                    this.analyseContent += '\n\n' + name + '\n';
                }
            }
            this.PurchaserNameQQ = '';
            this.$refs.analyseContent.focus();
        },
        async loadPurchases() {
            const { data } = await submit("/api/stall/getPurchaserList", {
                searchValue: '', ToPage: false
            });
            data.forEach(d => {
                d.PurchaserName = d.Name + (d.QQ ? '(' + d.QQ + ')' : '');
            });
            this.searchPurchases = data;
        },
        clear() {
            this.analyseContent = "";
            this.$refs.gridTable.init();
        },
        config() {
            this.$refs.analyseConfig.showDialog();
        },
        async analyse() {
            var that = this;
            if (!this.analyseContent) {
                this.$baseMessage("报单内容不可为空", "error");
                return;
            }
            this.$refs.gridTable.init(null, that.analyseContent);
        },
        async save() {
            if (this.analyseContent == "") return;
            this.$refs.gridTable.handleAddOrder();
        },
        handleImport() {
            const that = this;
            that.$nextTick(() => {
                that.$refs.TradeImport.initDialog('订单导入', '/api/stall/importTrade', '/static/Upload/订单导入模版-接单.xls', '订单导入模版-接单.xls');
            });
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .el-aside {
        .el-textarea {
            height: 100%;
        }

        .el-textarea__inner {
            height: 100%;
        }
    }

    .el-main {
        border-top: 0px !important;
        .stall-trade-table {
            height: 100%;
        }
    }
}
</style>
