<template>
  <el-dialog
    :title="title"
    :visible="visible"
    :close-on-click-modal="false"
    :width="editForm.dialogWidth"
    @close="close"
  >
    <edit-form ref="editForm" :formConfig="editForm"></edit-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
  name: "TradeEdit",
  props: {
    formConfig: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      visible: false,
      param: {},
      title: "",
      editForm: {},
    };
  },
  methods: {
    init(title = "", param = {}) {
      this.$nextTick(() => {
        this.title = title;
        this.param = param || {};
        this.editForm = {};
        this.editForm = this.formConfig
          ? {
              ...(this.formConfig || {}),
              data: { ...(this.formConfig.data || {}) },
              items: [...(this.formConfig.items || [])],
            }
          : {};
        this.editForm.data = { ...this.editForm.data, ...this.param };
        this.fetchData();
      });
      this.visible = true;
    },
    async fetchData() {
      let initData = {};
      if (this.editForm.url) {
        const { data } = await submit(this.editForm.url, this.param);
        initData = data.InitData || {};
        if (!this.param.type || this.param.type != "add") {
          this.editForm.data = { ...this.editForm.data, ...data };
        }
      }
      const { items, rules } = this.getFormRules(this.editForm.items, initData);
      this.$set(this.editForm, "items", items);
      this.$set(this.editForm, "rules", rules);
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          await submit(this.editForm.saveUrl, {
            ...this.editForm.data,
            ...this.param,
          });
          this.$baseMessage("保存成功", "success");
          this.$emit("handleQuery");
          this.close();
        } else {
          return false;
        }
      });
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
