var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialog-drag",rawName:"v-dialog-drag"}],attrs:{"title":_vm.title,"visible":_vm.visible,"close-on-click-modal":false,"width":_vm.editForm.dialogWidth},on:{"close":_vm.close}},[_c('el-tabs',{attrs:{"type":"border-card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"基础信息","name":"info"}},[_c('el-form',{ref:"editForm",attrs:{"model":_vm.editForm.data,"rules":_vm.editForm.rules,"inline":_vm.editForm.isInline,"label-position":"right","label-width":_vm.editForm.labelWidth,"inline-message":true}},[_vm._l((_vm.editForm.items.slice(0, 11)),function(item,index){return _c('edit-form-item',{key:index,attrs:{"form":_vm.editForm.data,"item":item,"labelWidth":_vm.editForm.labelWidth,"tipWidth":_vm.editForm.tipWidth}})})],2)],1),_c('el-tab-pane',{attrs:{"label":"商品图片","name":"img"}},[_c('div',[_c('el-upload',{ref:"img",attrs:{"action":_vm.uploadUrl,"headers":_vm.headers,"accept":"image/*","show-file-list":false,"on-success":function (response) { return _vm.uploadSuccess(response, 'img'); }}},[_c('el-button',{style:({ width: '80px' }),attrs:{"size":"small","type":"primary"}},[_vm._v("上传图片")])],1)],1),_c('div',{style:({
        height: '370px',
        'overflow-y': 'auto',
        border: '1px solid #ccc',
        margin: '10px 0',
        'padding-left': '10px',
      })},_vm._l((_vm.imgList),function(img,index){return _c('el-card',{key:index,style:({
          width: '150px',
          display: 'inline-block',
          margin: '10px 10px 0 0',
        }),attrs:{"body-style":{ padding: '0' }}},[_c('div',{style:({ width: '100%', height: '150px' })},[_c('img',{staticClass:"image",style:({ width: '100%', height: '100%' }),attrs:{"src":img.ImgPath}})]),_c('div',{style:({
            padding: '5px',
            background: '#e6e8ea',
            'text-align': 'right',
            position: 'relative',
          })},[(img.IsMain)?_c('i',{staticClass:"el-icon-check",style:({
              color: 'white',
              background: '#62cc62',
              position: 'absolute',
              top: '9px',
              left: '5px',
            })}):_vm._e(),_c('el-link',{attrs:{"type":"info","underline":false},on:{"click":function($event){return _vm.deleteImg(index, img.ImgID, img.ImgPath)}}},[_vm._v("[删除]")]),_c('el-link',{attrs:{"type":"info","underline":false},on:{"click":function($event){return _vm.setMainImg(index)}}},[_vm._v("[设为主图]")])],1)])}),1),_c('div',_vm._l((_vm.otherImgList),function(img){return _c('div',{key:img.prop,style:({
          'text-align': 'center',
          display: 'inline-block',
          'margin-right': '20px',
        })},[_c('img',{staticClass:"image",style:({
            width: '100px',
            height: '100px',
            'margin-bottom': '6px',
          }),attrs:{"src":(_vm.editForm.data || {})[img.prop] || _vm.noImgUrl}}),_c('el-upload',{ref:img.prop,refInFor:true,attrs:{"action":_vm.uploadUrl,"headers":_vm.headers,"accept":"image/*","show-file-list":false,"on-success":function (response) { return _vm.uploadSuccess(response, img.prop); }}},[_c('el-button',{style:({ width: '100px' }),attrs:{"size":"small","type":"primary"}},[_vm._v(_vm._s(img.label))])],1)],1)}),0)]),_c('el-tab-pane',{attrs:{"label":"商品规格","name":"spec"}},[_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.showProp}},[_vm._v("选择规格")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.autoCode}},[_vm._v("自动编码")])],1),_c('div',{style:({ 'margin-top': '10px' })},[_c('grid-table',{ref:"gridTable",attrs:{"tableConfig":_vm.tableConfig,"showCheckboxColumn":false,"showPagination":false},on:{"rowOperation":_vm.rowOperation}})],1),(_vm.propSelectorDialogVisible)?_c('prop-selector',{ref:"propSelector",on:{"setSpec":_vm.setSpec}}):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"商品描述","name":"description"}},[(_vm.editForm.data)?_c('ueditor-wrap',{attrs:{"config":{
        initialFrameWidth: 915,
        initialFrameHeight: 580,
      }},model:{value:(_vm.editForm.data.Description),callback:function ($$v) {_vm.$set(_vm.editForm.data, "Description", $$v)},expression:"editForm.data.Description"}}):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"商品扩展","name":"extend"}},[_c('el-form',{ref:"extendForm",attrs:{"model":_vm.editForm.data,"rules":_vm.editForm.rules,"inline":_vm.editForm.isInline,"label-position":"right","label-width":_vm.editForm.labelWidth,"inline-message":true}},_vm._l((_vm.editForm.items.slice(12)),function(item,index){return _c('edit-form-item',{key:index,attrs:{"form":_vm.editForm.data,"item":item,"labelWidth":_vm.editForm.labelWidth,"tipWidth":_vm.editForm.tipWidth}})}),1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("关闭")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }